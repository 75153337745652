import React, { useState, useEffect } from 'react';
import { getUsers, deleteUser } from '../services/userService';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Box,
  Paper,
  Card,
  CardContent,
  CardActions,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from '../components/DashboardLayout';

function UsersPage() {
  const [users, setUsers] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getUsers();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.response && error.response.data) {
        if (typeof error.response.data === 'string') {
          alert(`Error fetching users: ${error.response.data}`);
        } else if (error.response.data.errors) {
          const validationErrors = error.response.data.errors;
          let errorMessage = 'Error fetching users:\n';
          for (const field in validationErrors) {
            errorMessage += `${field}: ${validationErrors[field].join(', ')}\n`;
          }
          alert(errorMessage);
        } else {
          alert('An unexpected error occurred while fetching users.');
        }
      } else {
        alert('An unexpected error occurred while fetching users.');
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(id);
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        if (error.response && error.response.data) {
          if (typeof error.response.data === 'string') {
            alert(`Error deleting user: ${error.response.data}`);
          } else if (error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            let errorMessage = 'Error deleting user:\n';
            for (const field in validationErrors) {
              errorMessage += `${field}: ${validationErrors[field].join(', ')}\n`;
            }
            alert(errorMessage);
          } else {
            alert('An unexpected error occurred while deleting the user.');
          }
        } else {
          alert('An unexpected error occurred while deleting the user.');
        }
      }
    }
  };

  return (
    <DashboardLayout>
      <Container>
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/users/create')}
            sx={{ marginBottom: 2 }}
          >
            Add User
          </Button>

          {isMobile ? (
            // Mobile view: Card layout
            <Box>
              {users.map((user) => (
                <Card key={user.id} variant="outlined" sx={{ mb: 2 }}>
                  <CardContent>
                    <Typography variant="h6">{user.username}</Typography>
                    <Typography>Email: {user.email}</Typography>
                    <Typography>Role: {user.role}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate(`/users/edit/${user.id}`)}
                      sx={{ marginRight: 1 }}
                    >
                      Edit
                    </Button>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(user.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              ))}
              {users.length === 0 && (
                <Typography align="center" color="textSecondary" sx={{ mt: 2 }}>
                  No users found.
                </Typography>
              )}
            </Box>
          ) : (
            // Desktop view: Table layout
            <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate(`/users/edit/${user.id}`)}
                          sx={{ marginRight: 1 }}
                        >
                          Edit
                        </Button>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(user.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {users.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No users found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default UsersPage;
