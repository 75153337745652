// src/services/authService.js

const api = {
  baseURL: 'https://api.meguide.ae/api',
};

export const loginUser = async (credentials) => {
  try {
    const response = await fetch(`${api.baseURL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Server Error');
    }

    const data = await response.json();
    return data.token;
  } catch (error) {
    throw new Error(error.message || 'An unexpected error occurred');
  }
};
