import React, { useState, useContext } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Paper,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { loginUser } from '../services/authService';
import { motion } from 'framer-motion';
import logo from '../assets/logo2.png'; // Ensure the path to your logo is correct

function LoginPage() {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const token = await loginUser(credentials);
      login(token);
      navigate('/');
    } catch (err) {
      setError(err.message || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 3 }}>
          <Box mt={2} mb={4} textAlign="center">
            <motion.img
              src={logo}
              alt="MeGuide Trading Logo"
              style={{ width: '120px', height: 'auto', marginBottom: '20px' }}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.7 }}
            />
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                Welcome to <br /> MeGuide Trading
              </motion.div>
            </Typography>
            {error && (
              <Alert severity="error" sx={{ marginTop: '20px' }}>
                {error}
              </Alert>
            )}
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Username"
              name="username"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              value={credentials.username}
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Password"
              name="password"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              type="password"
              value={credentials.password}
              onChange={handleChange}
            />
            <Box mt={4} position="relative">
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ padding: 2 }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
                </Button>
              </motion.div>
            </Box>
          </form>
        </Paper>
      </motion.div>
    </Container>
  );
}

export default LoginPage;
