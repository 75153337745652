import api from '../services/api';

// Function to fetch all contacts
export const getContacts = async () => {
  try {
    const response = await api.get('/contacts');
    return response.data;
  } catch (error) {
    console.error('Error fetching contacts:', error);
    throw new Error('Unable to fetch contacts. Please try again later.');
  }
};

// Function to delete a contact by ID
export const deleteContact = async (contactId) => {
  try {
    await api.delete(`/contacts/${contactId}`);
  } catch (error) {
    console.error('Error deleting contact:', error);
    throw new Error('Unable to delete contact. Please try again later.');
  }
};
