import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box } from '@mui/material';
import { createProduct, getProductById, updateProduct } from '../services/productService';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function ProductForm() {
  const [initialValues, setInitialValues] = useState({
    id: null,
    name: '',
    metaDescription: '',
    targetKeyword: '',
    content: '',
    callToAction: '',
    heroImagePath: '',
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchProduct();
    }
  }, [id]);

  const fetchProduct = async () => {
    try {
      const data = await getProductById(id);
      setInitialValues({
        id: data.id,
        name: data.name,
        metaDescription: data.metaDescription,
        targetKeyword: data.targetKeyword,
        content: data.content,
        callToAction: data.callToAction,
        heroImagePath: data.heroImagePath,
      });
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  const ProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    metaDescription: Yup.string(),
    targetKeyword: Yup.string(),
    content: Yup.string(),
    callToAction: Yup.string(),
    heroImagePath: Yup.string(),
  });

  const handleSubmit = async (values) => {
    try {
      if (id) {
        await updateProduct(id, values);
      } else {
        await createProduct(values);
      }
      navigate('/products');
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  return (
    <DashboardLayout>
      <Container maxWidth="sm">
        <Box mt={4}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={ProductSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
              <Form>
                <TextField
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  label="Meta Description"
                  name="metaDescription"
                  value={values.metaDescription}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  multiline
                  rows={2}
                />
                <TextField
                  label="Target Keyword"
                  name="targetKeyword"
                  value={values.targetKeyword}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                
                {/* Rich Text Editor for Content */}
                <Box mt={2} mb={2}>
                  <label className="text-gray-700">Content</label>
                  <ReactQuill
                    theme="snow"
                    value={values.content}
                    onChange={(value) => setFieldValue('content', value)}
                    modules={{
                      toolbar: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ align: [] }],
                        [{ color: [] }, { background: [] }],
                        ['link', 'image'],
                        ['clean'],
                      ],
                    }}
                  />
                  {touched.content && errors.content && (
                    <p className="text-red-500 text-sm mt-2">{errors.content}</p>
                  )}
                </Box>

                <TextField
                  label="Call to Action"
                  name="callToAction"
                  value={values.callToAction}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                <TextField
                  label="Hero Image Path"
                  name="heroImagePath"
                  value={values.heroImagePath}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.heroImagePath && Boolean(errors.heroImagePath)}
                  helperText={touched.heroImagePath && errors.heroImagePath}
                />
                <Box mt={2}>
                  <Button type="submit" variant="contained" color="primary">
                    {id ? 'Update Product' : 'Create Product'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default ProductForm;
