import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ContactsPage from './pages/ContactsPage';
import ResourcesPage from './pages/ResourcesPage';
import ResourceForm from './components/ResourceForm';
import UsersPage from './pages/UsersPage';
import UserForm from './components/UserForm';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';

// Import additional components
import ProductsPage from './pages/ProductsPage';
import ProductForm from './components/ProductForm';
import BlogsPage from './pages/BlogList';
import BlogForm from './components/BlogForm';
import CategoriesPage from './pages/CategoryList';
import CategoryForm from './components/CategoryForm';

function App() {
  return (
    <AuthProvider>
      <Routes>
        {/* Public Route */}
        <Route path="/login" element={<LoginPage />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        {/* Blogs */}
        <Route
          path="/blogs"
          element={
            <PrivateRoute>
              <BlogsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/blogs/create"
          element={
            <PrivateRoute>
              <BlogForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/blogs/edit/:id"
          element={
            <PrivateRoute>
              <BlogForm />
            </PrivateRoute>
          }
        />

        {/* Categories */}
        <Route
          path="/categories"
          element={
            <PrivateRoute>
              <CategoriesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/categories/create"
          element={
            <PrivateRoute>
              <CategoryForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/categories/edit/:id"
          element={
            <PrivateRoute>
              <CategoryForm />
            </PrivateRoute>
          }
        />

        {/* Contacts */}
        <Route
          path="/contacts"
          element={
            <PrivateRoute>
              <ContactsPage />
            </PrivateRoute>
          }
        />

        {/* Products */}
        <Route
          path="/products"
          element={
            <PrivateRoute>
              <ProductsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/products/create"
          element={
            <PrivateRoute>
              <ProductForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/products/edit/:id"
          element={
            <PrivateRoute>
              <ProductForm />
            </PrivateRoute>
          }
        />

        {/* Resources */}
        <Route
          path="/resources"
          element={
            <PrivateRoute>
              <ResourcesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/resources/create"
          element={
            <PrivateRoute>
              <ResourceForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/resources/edit/:id"
          element={
            <PrivateRoute>
              <ResourceForm />
            </PrivateRoute>
          }
        />

        {/* Users */}
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <UsersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/create"
          element={
            <PrivateRoute>
              <UserForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/edit/:id"
          element={
            <PrivateRoute>
              <UserForm />
            </PrivateRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
