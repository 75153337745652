import React, { useState, useEffect } from 'react';
import { getProducts, deleteProduct } from '../services/productService';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Box,
  Paper,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from '../components/DashboardLayout';
import { useNavigate } from 'react-router-dom';

function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const data = await getProducts();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to load products.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await deleteProduct(id);
        fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
        setError('Failed to delete product.');
      }
    }
  };

  return (
    <DashboardLayout>
      <Container>
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/products/create')}
            sx={{ marginBottom: 2 }}
          >
            Add Product
          </Button>

          {isMobile ? (
            // Mobile view: Card layout
            <Grid container spacing={2}>
              {products.map((product) => (
                <Grid item xs={12} key={product.id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6">{product.name}</Typography>
                      <Typography>Meta Description: {product.metaDescription}</Typography>
                      {product.heroImagePath && (
                        <img
                          src={product.heroImagePath}
                          alt={product.name}
                          style={{ width: '100%', height: 'auto', marginTop: '10px' }}
                        />
                      )}
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate(`/products/edit/${product.id}`)}
                      >
                        Edit
                      </Button>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(product.id)}
                        sx={{ marginLeft: 'auto' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
              {products.length === 0 && (
                <Typography align="center" color="textSecondary" sx={{ width: '100%', marginTop: 2 }}>
                  No products found.
                </Typography>
              )}
            </Grid>
          ) : (
            // Desktop view: Table layout
            <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Meta Description</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.metaDescription}</TableCell>
                      <TableCell>
                        {product.heroImagePath && (
                          <img
                            src={product.heroImagePath}
                            alt={product.name}
                            width="50"
                            height="50"
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate(`/products/edit/${product.id}`)}
                          sx={{ marginRight: 1 }}
                        >
                          Edit
                        </Button>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(product.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {products.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No products found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default ProductsPage;
