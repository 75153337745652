// File: src/components/UserForm.js

import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, MenuItem } from '@mui/material';
import { createUser, getUserById, updateUser } from '../services/userService';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function UserForm() {
  const [initialValues, setInitialValues] = useState({
    username: '',
    email: '',
    password: '',
    role: '',
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const roles = ['Admin', 'Editor', 'Viewer']; // Adjust roles as per your application

  useEffect(() => {
    if (id) {
      fetchUser();
    }
  }, [id]);

  const fetchUser = async () => {
    try {
      const data = await getUserById(id);
      setInitialValues({
        id: data.id,
        username: data.username,
        email: data.email,
        password: '', // Leave password empty for security
        role: data.role,
      });
    } catch (error) {
      console.error('Error fetching user:', error);
      alert('Failed to fetch user data.');
    }
  };

  const UserSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: id ? Yup.string() : Yup.string().required('Password is required'),
    role: Yup.string().required('Role is required'),
  });

  const handleSubmit = async (values) => {
    try {
      if (id) {
        // When updating, include the 'id' in the request body
        await updateUser(id, values);
      } else {
        // When creating, exclude 'id' from the request body
        const { id, ...userData } = values;
        await createUser(userData);
      }
      navigate('/users');
    } catch (error) {
      console.error('Error saving user:', error);
      // Handle error messages
      if (error.response && error.response.data) {
        if (error.response.data.errors) {
          const validationErrors = error.response.data.errors;
          let errorMessage = 'Validation Errors:\n';
          for (const field in validationErrors) {
            errorMessage += `${field}: ${validationErrors[field].join(', ')}\n`;
          }
          alert(errorMessage);
        } else if (typeof error.response.data === 'string') {
          // If error message is a simple string
          alert(`Error: ${error.response.data}`);
        } else {
          alert('An unexpected error occurred.');
        }
      } else {
        alert('An unexpected error occurred.');
      }
    }
  };

  return (
    <DashboardLayout>
      <Container maxWidth="sm">
        <Box mt={4}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={UserSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                {/* Username Field */}
                <TextField
                  label="Username"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.username && Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                />

                {/* Email Field */}
                <TextField
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />

                {/* Password Field */}
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required={!id}
                  error={touched.password && Boolean(errors.password)}
                  helperText={
                    id
                      ? 'Leave blank to keep current password'
                      : touched.password && errors.password
                  }
                />

                {/* Role Field */}
                <TextField
                  label="Role"
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  select
                  error={touched.role && Boolean(errors.role)}
                  helperText={touched.role && errors.role}
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Submit Button */}
                <Box mt={2}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    {id ? 'Update User' : 'Create User'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default UserForm;
