// src/components/CategoryList.js
import React, { useState, useEffect } from 'react';
import { getCategories, deleteCategory } from '../services/categoryService';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Box,
  Paper,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DashboardLayout from '../components/DashboardLayout';
import { useNavigate } from 'react-router-dom';

function CategoryList() {
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await deleteCategory(id);
        fetchCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  };

  return (
    <DashboardLayout>
      <Container>
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/categories/create')}
            sx={{ marginBottom: 2 }}
          >
            Add Category
          </Button>

          {isMobile ? (
            <Grid container spacing={2}>
              {categories.map((category) => (
                <Grid item xs={12} key={category.categoryId}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6">{category.name}</Typography>
                      <Typography>Description: {category.description}</Typography>
                    </CardContent>
                    <CardActions>
                      <IconButton
                        color="primary"
                        onClick={() => navigate(`/categories/edit/${category.categoryId}`)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(category.categoryId)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
              {categories.length === 0 && (
                <Typography align="center" color="textSecondary" sx={{ width: '100%', marginTop: 2 }}>
                  No categories found.
                </Typography>
              )}
            </Grid>
          ) : (
            <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.map((category) => (
                    <TableRow key={category.categoryId}>
                      <TableCell>{category.name}</TableCell>
                      <TableCell>{category.description}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          onClick={() => navigate(`/categories/edit/${category.categoryId}`)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(category.categoryId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {categories.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No categories found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default CategoryList;
