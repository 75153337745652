// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

const TOKEN_EXPIRATION_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const login = (token) => {
    const decoded = jwtDecode(token);
    const expirationTime = Date.now() + TOKEN_EXPIRATION_TIME; // Set token expiration

    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiration', expirationTime.toString());

    setUser(decoded);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    setUser(null);
    navigate('/login');
  };

  const checkTokenExpiration = () => {
    const expirationTime = localStorage.getItem('tokenExpiration');
    if (expirationTime && Date.now() > Number(expirationTime)) {
      logout();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const expirationTime = localStorage.getItem('tokenExpiration');

        if (expirationTime && Date.now() < Number(expirationTime)) {
          setUser(decoded);
        } else {
          logout(); // Token expired, log out the user
        }
      } catch (error) {
        console.error('Invalid token:', error);
        logout();
      }
    }

    // Check token expiration every minute
    const interval = setInterval(checkTokenExpiration, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
