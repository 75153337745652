// src/components/BlogForm.js
import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, MenuItem, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getCategories } from '../services/categoryService';
import { createBlog, updateBlog, getBlogById } from '../services/blogService';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';

function BlogForm() {
  const [categories, setCategories] = useState([]);
  const [initialValues, setInitialValues] = useState({
    title: '',
    content: '',
    metaDescription: '',
    metaTitle: '',
    category: '',
    imageUrl: '',
    altText: '',
    slug: ''
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
    if (id) fetchBlog();
  }, [id]);

  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };

  const fetchBlog = async () => {
    try {
      const data = await getBlogById(id);
      setInitialValues({
        title: data.title,
        content: data.content,
        metaDescription: data.metaDescription,
        metaTitle: data.metaTitle,
        category: data.category,
        imageUrl: data.imageUrl,
        altText: data.altText,
        slug: data.slug
      });
    } catch (error) {
      console.error('Error fetching blog:', error);
    }
  };

  const BlogSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    content: Yup.string().required('Content is required'),
    metaDescription: Yup.string().max(300, 'Meta description should not exceed 300 characters'),
    metaTitle: Yup.string().max(150, 'Meta title should not exceed 150 characters'),
    category: Yup.string().required('Please select a category'),
    altText: Yup.string().required('Alt text is required for the image')
  });

  const handleSubmit = async (values) => {
    try {
      if (id) {
        await updateBlog(id, values);
      } else {
        await createBlog(values);
      }
      navigate('/blogs');
    } catch (error) {
      console.error('Error saving blog:', error);
      alert('Error saving blog');
    }
  };

  return (
    <DashboardLayout>
      <Container maxWidth="md">
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            {id ? 'Edit Blog Post' : 'Create Blog Post'}
          </Typography>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={BlogSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
              <Form>
                <TextField
                  label="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
                <Box mt={2} mb={2}>
                  <Typography variant="subtitle1" gutterBottom>
                    Content
                  </Typography>
                  <ReactQuill
                    theme="snow"
                    value={values.content}
                    onChange={(value) => setFieldValue('content', value)}
                    modules={{
                      toolbar: [
                        [{ header: '1' }, { header: '2' }, { font: [] }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ align: [] }],
                        [{ color: [] }, { background: [] }],
                        ['link', 'image'],
                        ['clean'],
                      ],
                    }}
                  />
                  {touched.content && errors.content && (
                    <Typography color="error">{errors.content}</Typography>
                  )}
                </Box>
                <TextField
                  label="Meta Title"
                  name="metaTitle"
                  value={values.metaTitle}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.metaTitle && Boolean(errors.metaTitle)}
                  helperText={touched.metaTitle && errors.metaTitle}
                />
                <TextField
                  label="Meta Description"
                  name="metaDescription"
                  value={values.metaDescription}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  multiline
                  rows={3}
                  error={touched.metaDescription && Boolean(errors.metaDescription)}
                  helperText={touched.metaDescription && errors.metaDescription}
                />
                <TextField
                  select
                  label="Category"
                  name="category"
                  value={values.category}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.category && Boolean(errors.category)}
                  helperText={touched.category && errors.category}
                >
                  {categories.map((cat) => (
                    <MenuItem key={cat.id} value={cat.name}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Slug"
                  name="slug"
                  value={values.slug}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.slug && Boolean(errors.slug)}
                  helperText={touched.slug && errors.slug}
                />
                <TextField
                  label="Image URL"
                  name="imageUrl"
                  value={values.imageUrl}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.imageUrl && Boolean(errors.imageUrl)}
                  helperText={touched.imageUrl && errors.imageUrl}
                />
                <TextField
                  label="Alt Text"
                  name="altText"
                  value={values.altText}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.altText && Boolean(errors.altText)}
                  helperText={touched.altText && errors.altText}
                />
                <Box mt={4}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    {id ? 'Update Blog Post' : 'Create Blog Post'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default BlogForm;
