// src/services/blogService.js
import api from './api';

// Retrieve all blogs
export const getBlogs = async () => {
  try {
    const response = await api.get('/blogs');
    return response.data || [];
  } catch (error) {
    console.error('Error in getBlogs:', error);
    throw error;
  }
};

// Retrieve a blog by ID
export const getBlogById = async (id) => {
  try {
    const response = await api.get(`/blogs/id/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error in getBlogById:', error);
    throw error;
  }
};

// Retrieve a blog by Slug
export const getBlogBySlug = async (slug) => {
  try {
    const response = await api.get(`/blogs/${slug}`);
    return response.data;
  } catch (error) {
    console.error('Error in getBlogBySlug:', error);
    throw error;
  }
};

// Create a new blog
export const createBlog = async (blogData) => {
  try {
    const response = await api.post('/blogs', blogData);
    return response.data;
  } catch (error) {
    console.error('Error in createBlog:', error);
    throw error;
  }
};

// Update an existing blog by ID
export const updateBlog = async (id, blogData) => {
  try {
    await api.put(`/blogs/${id}`, blogData);
  } catch (error) {
    console.error('Error in updateBlog:', error);
    throw error;
  }
};

// Delete a blog by ID
export const deleteBlog = async (id) => {
  try {
    await api.delete(`/blogs/${id}`);
  } catch (error) {
    console.error('Error in deleteBlog:', error);
    throw error;
  }
};
