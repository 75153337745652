import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import logo from '../assets/logo2.png'; // Ensure the path to your logo image is correct

function Dashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DashboardLayout>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 64px)" // Adjust height to account for the AppBar
        width="100%"
        bgcolor={theme.palette.background.default}
        p={2}
      >
        <Box
          component="img"
          src={logo}
          alt="MeGuide Consulting Logo"
          sx={{
            maxWidth: { xs: '70%', sm: '50%', md: '40%', lg: '30%' }, // Adjusted for larger responsiveness
            height: 'auto', // Maintain aspect ratio
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)', // Hover effect
            },
          }}
        />
        {!isMobile && (
          <Typography
            variant="h5"
            align="center"
            sx={{
              mt: 4,
              color: theme.palette.text.primary,
              fontWeight: 'bold',
            }}
          >
            Welcome to MeGuide Trading Dashboard
          </Typography>
        )}
      </Box>
    </DashboardLayout>
  );
}

export default Dashboard;
