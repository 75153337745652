import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box } from '@mui/material';
import { createResource, getResourceById, updateResource } from '../services/resourceService';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function ResourceForm() {
  const [initialValues, setInitialValues] = useState({
    resourceId: null,  // Initialize with null
    title: '',
    content: '',
    type: '',
    imageSrc: ''
  });
  const { id } = useParams();  // Get ID from route params
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchResource();
    }
  }, [id]);

  const fetchResource = async () => {
    try {
      const data = await getResourceById(id);  // Fetch resource by ID
      setInitialValues({
        resourceId: data.resourceId,  // Set resourceId in initial values
        title: data.title,
        content: data.content,
        type: data.type,
        imageSrc: data.imageSrc || ''
      });
    } catch (error) {
      console.error('Error fetching resource:', error);
      alert('Failed to fetch resource data.');
    }
  };

  const ResourceSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    content: Yup.string().required('Content is required'),
    type: Yup.string().required('Type is required'),
    imageSrc: Yup.string().url('Must be a valid URL')
  });

  const handleSubmit = async (values) => {
    try {
      if (id) {
        await updateResource(id, values);  // Update the resource
      } else {
        await createResource(values);  // Create a new resource
      }
      navigate('/resources');  // Redirect back to the resource list
    } catch (error) {
      console.error('Error saving resource:', error);
      alert('Error saving resource.');
    }
  };

  return (
    <DashboardLayout>
      <Container maxWidth="sm">
        <Box mt={4}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={ResourceSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <TextField
                  label="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
                <TextField
                  label="Content"
                  name="content"
                  value={values.content}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  multiline
                  rows={4}
                  required
                  error={touched.content && Boolean(errors.content)}
                  helperText={touched.content && errors.content}
                />
                <TextField
                  label="Type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.type && Boolean(errors.type)}
                  helperText={touched.type && errors.type}
                />
                <TextField
                  label="Image URL"
                  name="imageSrc"
                  value={values.imageSrc}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={touched.imageSrc && Boolean(errors.imageSrc)}
                  helperText={touched.imageSrc && errors.imageSrc}
                />
                <Box mt={2}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    {id ? 'Update Resource' : 'Create Resource'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default ResourceForm;
