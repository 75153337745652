import api from './api';

// Fetch all resources
export const getResources = async () => {
  try {
    const response = await api.get('/resources');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch a single resource by ID
export const getResourceById = async (id) => {
  try {
    const response = await api.get(`/resources/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Create a new resource
export const createResource = async (resource) => {
  try {
    const response = await api.post('/resources', resource);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update an existing resource
export const updateResource = async (id, resource) => {
  try {
    const response = await api.put(`/resources/${id}`, {
      ...resource,
      resourceId: id,  // Ensure the resourceId is included in the payload
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a resource
export const deleteResource = async (id) => {
  try {
    const response = await api.delete(`/resources/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
