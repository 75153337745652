// src/components/BlogList.js
import React, { useState, useEffect } from 'react';
import { getBlogs, deleteBlog } from '../services/blogService';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Box,
  Paper,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DashboardLayout from '../components/DashboardLayout';
import { useNavigate } from 'react-router-dom';

function BlogList() {
  const [blogs, setBlogs] = useState([]); // Initialize with an empty array
  const [error, setError] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const data = await getBlogs();
      if (Array.isArray(data)) {
        setBlogs(data); // Ensure data is an array
      } else {
        setBlogs([]); // Fallback to empty array if data is not an array
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setError('Failed to load blog posts.');
      setBlogs([]); // Set blogs to an empty array on error
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this blog post?')) {
      try {
        await deleteBlog(id);
        fetchBlogs(); // Refresh list after deletion
      } catch (error) {
        console.error('Error deleting blog post:', error);
        setError('Failed to delete blog post.');
      }
    }
  };

  return (
    <DashboardLayout>
      <Container>
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            Blog Posts
          </Typography>
          {error && <Typography color="error" gutterBottom>{error}</Typography>}
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/blogs/create')}
            sx={{ marginBottom: 2 }}
          >
            Create Blog Post
          </Button>

          {isMobile ? (
            <Grid container spacing={2}>
              {blogs.map((blog) => (
                <Grid item xs={12} key={blog.blogId}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6">{blog.title}</Typography>
                      <Typography>Category: {blog.category}</Typography>
                      <Typography>Published on: {new Date(blog.publishDate).toLocaleDateString()}</Typography>
                    </CardContent>
                    <CardActions>
                      <IconButton
                        color="primary"
                        onClick={() => navigate(`/blogs/edit/${blog.blogId}`)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(blog.blogId)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
              {blogs.length === 0 && (
                <Typography align="center" color="textSecondary" sx={{ width: '100%', marginTop: 2 }}>
                  No blog posts found.
                </Typography>
              )}
            </Grid>
          ) : (
            <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Publish Date</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {blogs.map((blog) => (
                    <TableRow key={blog.blogId}>
                      <TableCell>{blog.title}</TableCell>
                      <TableCell>{blog.category}</TableCell>
                      <TableCell>{new Date(blog.publishDate).toLocaleDateString()}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          onClick={() => navigate(`/blogs/edit/${blog.blogId}`)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(blog.blogId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {blogs.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No blog posts found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default BlogList;
