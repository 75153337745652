import React, { useState, useEffect } from 'react';
import { getResources, deleteResource } from '../services/resourceService';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Box,
  Paper,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from '../components/DashboardLayout';
import { useNavigate } from 'react-router-dom';

function ResourcesPage() {
  const [resources, setResources] = useState([]);
  const [error, setError] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    try {
      const data = await getResources();
      setResources(data);
    } catch (error) {
      console.error('Error fetching resources:', error);
      setError('An unexpected error occurred while fetching resources.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this resource?')) {
      try {
        await deleteResource(id);
        fetchResources(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting resource:', error);
        setError('An unexpected error occurred while deleting the resource.');
      }
    }
  };

  return (
    <DashboardLayout>
      <Container>
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            Resources
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/resources/create')}
            sx={{ marginBottom: 2 }}
          >
            Add Resource
          </Button>

          {isMobile ? (
            // Mobile view: Cards layout
            <Grid container spacing={2}>
              {resources.map((resource) => (
                <Grid item xs={12} key={resource.resourceId}>
                  <Paper variant="outlined">
                    <Box p={2}>
                      <Typography variant="h6">{resource.title}</Typography>
                      <Typography>Type: {resource.type}</Typography>
                      {resource.imageSrc && (
                        <img
                          src={resource.imageSrc}
                          alt={resource.title}
                          style={{ width: '100%', height: 'auto', marginTop: '10px' }}
                        />
                      )}
                      <Typography sx={{ marginTop: '10px' }}>{resource.content}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate(`/resources/edit/${resource.resourceId}`)}
                      >
                        Edit
                      </Button>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(resource.resourceId)}
                        sx={{ marginLeft: 'auto' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Paper>
                </Grid>
              ))}
              {resources.length === 0 && (
                <Typography align="center" color="textSecondary" sx={{ width: '100%', marginTop: 2 }}>
                  No resources found.
                </Typography>
              )}
            </Grid>
          ) : (
            // Desktop view: Table layout
            <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Content</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resources.map((resource) => (
                    <TableRow key={resource.resourceId}>
                      <TableCell>{resource.title}</TableCell>
                      <TableCell>{resource.content}</TableCell>
                      <TableCell>{resource.type}</TableCell>
                      <TableCell>
                        {resource.imageSrc && (
                          <img
                            src={resource.imageSrc}
                            alt={resource.title}
                            width="50"
                            height="50"
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate(`/resources/edit/${resource.resourceId}`)}
                          sx={{ marginRight: 1 }}
                        >
                          Edit
                        </Button>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(resource.resourceId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {resources.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No resources found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default ResourcesPage;
