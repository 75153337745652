// src/components/CategoryForm.js
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import { createCategory, getCategoryById, updateCategory } from '../services/categoryService';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DashboardLayout from './DashboardLayout';

function CategoryForm() {
  const [initialValues, setInitialValues] = useState({
    categoryId: null,
    name: '',
    description: '',
  });
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) fetchCategory();
  }, [id]);

  const fetchCategory = async () => {
    try {
      setLoading(true);
      const data = await getCategoryById(id);
      setInitialValues({
        categoryId: data.categoryId,
        name: data.name,
        description: data.description,
      });
    } catch (error) {
      console.error('Error fetching category:', error);
    } finally {
      setLoading(false);
    }
  };

  const CategorySchema = Yup.object().shape({
    name: Yup.string().required('Category name is required'),
    description: Yup.string().max(300, 'Max 300 characters'),
  });

  const handleSubmit = async (values) => {
    try {
      if (id) {
        await updateCategory(id, values);
      } else {
        await createCategory(values);
      }
      navigate('/categories');
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  return (
    <DashboardLayout>
      <Container maxWidth="sm">
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            {id ? 'Edit Category' : 'Create Category'}
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={CategorySchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleChange }) => (
                <Form>
                  <TextField
                    label="Category Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    label="Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiline
                    rows={3}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                  <Box mt={3}>
                    <Button type="submit" variant="contained" color="primary">
                      {id ? 'Update Category' : 'Create Category'}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default CategoryForm;
